<template>
  <form ref="myForm" @submit="onSubmit">
    <div class="flex flex-col">
      <label for="bio">Bio</label>
      <Field v-model="getAccount.bio" v-slot="{ field }" name="bio">
        <textarea v-bind="field" />
      </Field>
      <ErrorMessage name="bio" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm, ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { useAccount } from '@/composables/useAccount'
import { inject, Ref } from "vue";
const { handleSubmit } = useForm()

const { getAccount, updateAccount } = useAccount();

const currentStep = inject<Ref<number>>('currentStep');

const schema = {
  bio: yup.string()
}

const emit = defineEmits<{
  (e: 'processing', arg1: boolean): void
  (e: 'formSubmitted', arg1: boolean): void
}>()

const onSubmit = handleSubmit(onSuccess)

async function onSuccess(values: any) {
  if (typeof currentStep !== 'undefined') {
    emit('processing', true);


    await updateAccount({
      onboarding_step: currentStep.value + 1,
      ...values
    })

    emit('processing', false)

    emit('formSubmitted', true)
  }
}
</script>
