<template>
  <Form @submit="onSubmit" @invalid-submit="onInvalidSubmit">
    <div class="flex flex-col">
      <label for="profile_pic">Profile Pic</label>
      <Field name="profile_pic" v-slot="{ handleChange, handleBlur }">
        <input type="file" @change="handleChange" @blur="handleBlur" />
      </Field>
      <ErrorMessage name="profile_pic" />
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ErrorMessage, Field, Form } from 'vee-validate'

const emit = defineEmits<{
  (e: 'submit', formData: any): void
}>()

function onSubmit(values: any) {
  alert(JSON.stringify(values, null, 2))
  emit('submit', values);
}
</script>
