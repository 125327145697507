import EditInterestsForm from "~/components/account/EditProfile/EditInterestsForm.vue";
import OnBoardingProfileBioForm from "~/components/account/AccountCreation/Onboarding/OnBoardingProfileBioForm.vue";
import OnBoardingProfilePicForm from "~/components/account/AccountCreation/Onboarding/OnBoardingProfilePicForm.vue";

export function useOnboarding() {

  const getSteps = () => {
    return [
      {
        title: 'Add your interests',
        component: EditInterestsForm
      },
      {
        title: 'Add bio',
        component: OnBoardingProfileBioForm
      },
      {
        title: 'Add profile picture',
        component: OnBoardingProfilePicForm
      },
    ]
  }

  return {
    getSteps,
  }
}
